import React, { Children, useState, useContext, createContext } from 'react'

const initialContextValue = {}
const Context = createContext(initialContextValue)

const Provider = (props) => {
    const { appState } = props
    const [tmpAppState, setState] = useState(appState)
    const setAppState = (s) => {
        setState((oldState) => ({ ...oldState, ...s }))
    }
    return (
        <Context.Provider
            value={{
                appState: tmpAppState,
                setAppState: setAppState
            }}>
            {Children.only(props.children)}
        </Context.Provider>
    )
}

const useAppState = () => {
    const { appState, setAppState } = useContext(Context)
    return [appState, setAppState]
}

export default Provider


export function withAppState(Component) {
    return function AppStateComponent(props) {
        return (
            <Context.Consumer>
                {({ appState, setAppState }) => <Component {...props} appState={appState} setAppState={setAppState} />}
            </Context.Consumer>
        );
    };
}

export { useAppState }