import React from "react";
import App, { Container } from "next/app";
import Head from "next/head";
import Router from "next/router";
import { IntlProvider, addLocaleData } from "react-intl";
import AppStateProvider from "../src/contexts/app-state";
import { getUserInfo } from "../src/utils/auth";

const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { PIXEX_CODE } = publicRuntimeConfig;

if (typeof window !== "undefined" && window.ReactIntlLocaleData) {
  Object.keys(window.ReactIntlLocaleData).forEach((lang) => {
    addLocaleData(window.ReactIntlLocaleData[lang]);
  });
}

class DiscoolverApp extends App {
  static async getInitialProps({ Component, router, ctx }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const user = await getUserInfo(ctx);

    const { req } = ctx;
    const { locale, messages } = req || window.__NEXT_DATA__.props;

    return { user, pageProps, locale, messages };
  }
  constructor(props) {
    super(props);
    this.state = {
      appState: {
        token: props.user.token,
        user: props.user
      },
    };
  }
  componentDidMount = () => {
    const { timeInactive } = this.state;
    const totem = localStorage.getItem('totem');
    if(totem){
      console.log('totem', `${totem}`)
      const pageTotem = `/${this.props.locale}/${this.props.pageProps.destination.rawId}/totem/${totem}`;
      if(this.props.pageProps.pathname !== pageTotem){
        setInterval(() => {
          Router.push(
            pageTotem
          );
        }, 1200000);
      }
    }
  }
  render() {
    const { Component, pageProps, locale, messages } = this.props;
    const { appState } = this.state;

    const now = Date.now();

    return (
      <AppStateProvider appState={appState}>
        <IntlProvider locale={locale} messages={messages} initialNow={now}>
          <>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=10, user-scalable=no"
              />
            </Head>
            <div onContextMenu={(e)=> e.preventDefault()}>
              <Component {...pageProps} />
            </div>
          </>
        </IntlProvider>
      </AppStateProvider>
    );
  }
}

export default DiscoolverApp;
